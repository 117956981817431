<mat-sidenav-container class="pages-container">

    <div class="theme-container main">
        <router-outlet></router-outlet>
    </div>
    <!-- <app-footer></app-footer> -->

    <div *ngIf="showBackToTop" fxLayout="row" fxLayoutAlign="center center" class="back-to-top transition" (click)="scrollToTop()">
        <mat-icon>arrow_upward</mat-icon>
    </div>

 </mat-sidenav-container>
