import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ResetPassword, Token, User, loginModel } from './_helpers/user.model';
import { ApiRespone } from '../models/ApiResponse.model';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  private userSubject: BehaviorSubject<User | null>;
  public user: Observable<User | null>;
  public loginRedirectUrl: string | undefined;
  constructor(
      private router: Router,
      private http: HttpClient
  ) {
      this.userSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user')!));
      this.user = this.userSubject.asObservable();
  }

  public get userValue() {
      return this.userSubject.value;
  }

  login(model:loginModel):Observable<ApiRespone<any>> {

    const url = `${environment.baseUrl}/api/Account/login`;

    return this.http.post<any>(url, model)
      .pipe(map(x => {

        if(x.succeeded === true) {
          localStorage.setItem('user', JSON.stringify(x.data));
          const helper =new JwtHelperService();
          const decodedToken =helper.decodeToken(x.data) as Token;
          localStorage.setItem('role', JSON.stringify(decodedToken.Role));
        
         this.userSubject.next(
           x.data
           );

            return x.data;
        }
        else{
          alert(x.message);
        }

  }));

  }
  public get isloggedIn() {
    //return this._isLoggedIn.asObservable();
     return localStorage.getItem('user') !== null;
  }
  logout() {
      // remove user from local storage to log user out
      localStorage.removeItem('user');
      localStorage.removeItem('role');
      this.userSubject.next(null);
      this.router.navigate(['/']);
  }

  public get currentuser() {
    const item = `${localStorage.getItem('user')}`;
  const helper =new JwtHelperService();
  const decodedToken =helper.decodeToken(item) as Token;
  if(!this.isloggedIn){
    this.logout();
  }
  return decodedToken;
  }


  getRole() {
    
  const role = `${localStorage.getItem('role')}`;
  
 return role;
  }
  register(model: any):Observable<ApiRespone<any>>{
    
  const endpoint ='api/account/register-user';

  return this.http.post<ApiRespone<any>>(`${environment.baseUrl}/${endpoint}` , model);

  }


  forgotPassword(username: any): Observable<any> {
    const endpoint ='api/account/forgot-password/';

  return this.http.post<any>(`${environment.baseUrl}/${endpoint}`,  username);

  }


  
  resetPassword(model: ResetPassword): Observable<any> {
    const endpoint ='api/account/reset-password/';

  return this.http.post<any>(`${environment.baseUrl}/${endpoint}`,  model);

  }
  // const  user= new User
}

