import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AuthenticationService } from '../authentication.service';
import { catchError, tap } from 'rxjs/operators'
import { SpinnerService } from '../../spinner.service';

@Injectable({
  providedIn: 'root'
})

export class TokenInterceptorService implements HttpInterceptor{

  constructor(private authSvc:AuthenticationService,private spinnerService: SpinnerService) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const  token  = this.authSvc.userValue;
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return next.handle(request)
    .pipe(tap((event: HttpEvent<any>) => {
if(event instanceof HttpResponse) {
  this.spinnerService.hide();
}
    },
  
      catchError((err) => {
        if (err.status === 401) {
          this.authSvc.logout();
        }
        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    ));
  }
}
